<template>
  <VContainer
    class="fill-height"
    style="position: relative;"
  >
    <VSpeedDial
      data-test="back-button"
      absolute
      top
      left
    >
      <template #activator>
        <VBtn
          fab
          depressed
          retain-focus-on-click
          width="46"
          height="46"
          color="tt-black lighten-5"
          elevation="0"
          @click="$router.go(-1)"
        >
          <VIcon size="14">
            $prev
          </VIcon>
        </VBtn>
      </template>
    </VSpeedDial>

    <VRow align="center">
      <VCol
        md="11"
        offset-md="1"
      >
        <h1 class="display-8 font-weight-bold">
          404
        </h1>

        <h2 class="text-h2 font-weight-bold">
          {{ $t('Error404.subtitle') }}
        </h2>

        <p class="mt-6 mb-12 tiny-text">
          {{ $t('Error404.description') }}
        </p>

        <VBtn
          x-large
          depressed
          elevation="0"
          :to="{ name : Names.R_APP }"
        >
          {{ $t('Error404.btnTitle') }}
          <VIcon right>
            $next
          </VIcon>
        </VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'Error404',

  inject: ['Names'],
};
</script>
